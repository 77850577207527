import React, { useState, useEffect } from "react";

export default function Welcome() {
  const [days, setDaysLeft] = useState(140);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [font, setFont] = useState(20);

  const formatNum = (num) => {
    if (num < 10) return `0${num}`;
    return num;
  };
  const convertMillisecondsToDHMS = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const remainingHours = hours % 24;
    const remainingMinutes = minutes % 60;
    const remainingSeconds = seconds % 60;

    return {
      days: formatNum(days),
      hours: formatNum(remainingHours),
      minutes: formatNum(remainingMinutes),
      seconds: formatNum(remainingSeconds),
    };
  };

  const getTimes = () => {
    let curDate = new Date();
    let targetDate = new Date("June 8, 2024 13:00:00");

    const diffTime = Math.abs(targetDate - curDate);
    const daysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const maxDays = 140;

    // if (daysLeft === 1) {
    //   return "1 day left!";
    // }
    // if (daysLeft === 0) {
    //   return "It's today!";
    // }
    // if (daysLeft < 0) {
    //   return "You're married!!!";
    // }

    const fontWeight = (100 - (daysLeft / maxDays) * 100) / 100;
    let fontSize = 50 + 100 * fontWeight;
    const timesLeft = convertMillisecondsToDHMS(diffTime);
    setFont(fontSize);
    setDaysLeft(timesLeft.days);
    setHours(timesLeft.hours);
    setMinutes(timesLeft.minutes);
    setSeconds(timesLeft.seconds);
  };

  useEffect(() => {
    getTimes();

    const interval = setInterval(() => {
      // setCountDown(countDownDate - new Date().getTime());
      getTimes();
    }, 1000);

    return () => clearInterval(interval);
  });
  return (
    <div class="main">
      <div style={{ fontSize: "16px" }}>Time left:</div>
      <div class="options">
        <div className="timeOp">
          <div>{days}</div>
          <div class="indicator">Days</div>
        </div>
        <div>:</div>
        <div className="timeOp">
          <div>{hours}</div>
          <div class="indicator">Hours</div>
        </div>
        <div>:</div>

        <div className="timeOp">
          <div>{minutes}</div>
          <div class="indicator">Minutes</div>
        </div>
        <div>:</div>

        <div className="timeOp">
          <div>{seconds}</div>
          <div class="indicator">Seconds</div>
        </div>
      </div>
    </div>
  );
}
