import logo from "./logo.svg";
import "./App.css";
import Countdown from "./Countdown";

function App() {
  return (
    <div className="App" id="root">
      <link
        rel="icon"
        href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎯</text></svg>"
      ></link>

      <header className="App-header">
        <div className="decorations">
          <div className="content">
            <img src="./watercolor.png" className="water1" alt="watercolor" />
            <img src="./watercolor.png" className="water2" alt="watercolor" />
            <img src="./watercolor.png" className="water3" alt="watercolor" />
            <img src="./gumleaf.png" className="leaf1" alt="leaf" />
            <img src="./gumleaf.png" className="leaf2" alt="leaf" />
          </div>
        </div>
        <Countdown />
      </header>
    </div>
  );
}

export default App;
